@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,400i,700&display=swap);
body {
  font-family: "Josefin Sans", sans-serif !important;
}

#nav2 a {
  color: white;
}

.article-content .ques {
  font-size: 1.2em;
  margin-top: 25px;
}

/* Big geo search buttons on /search */

#geoSType .col-6 {
  padding-bottom: 15px;
}

#geoSType .col-6:nth-of-type(even) {
  padding-left: 7px;
}
#geoSType .col-6:nth-of-type(odd) {
  padding-right: 7px;
}

#geoSType .col-6 button {
  height: 130px;
}

/* Support center */

.help-content img {
  max-width: 600px;
  margin: 0 auto;
  border: solid 1px lightgray;
  border-radius: 4px;
  margin: 20px;
  display: inline-block;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/* Usage page */
table.usageTable {
  max-width: 500px;
}

table.usageTable tr td p {
  margin-bottom: 0px;
}

table.usageTable tr td:nth-child(2) {
  font-size: 1.5em;
  text-align: right;
  vertical-align: middle;
}

/* Sidebar */
div.sideBar a.nav-link {
  color: #00000080;
}

div.sideBar a.nav-link.sub-nav {
  color: #00000080;
  font-size: 0.9em;
}

div.sideBar a.nav-link:hover {
  color: #000000b3;
}

div.sideBar a.nav-link.activeSidebar {
  color: #000000b3;
  font-size: 1.1em;
}

/* Search again filters */

#saForm {
  background-color: white;
}

#saForm h5 {
  margin-top: 50px;
}

#saForm .form-group {
  margin-bottom: 50px;
}

#saForm label.form-label:not(.col-form-label) {
  font-size: 1.1em;
}

/* Compare Plans Pricing Page */

#pricing table tbody tr td {
  font-size: 1.2em;
}

#pricing table thead tr th:first-of-type,
#pricing table tbody tr td:first-of-type {
  text-align: left;
  font-size: 1em;
}

#pricing table thead tr th,
#pricing table tbody tr td {
  text-align: center;
}

#pricing table thead tr th .planName {
  font-size: 1.1em;
}

#pricing table thead tr th .price {
  font-size: 2.1em;
  font-weight: 300;
}

#pricing table thead tr th .period {
  font-size: 0.8em;
  color: #bbbbbb;
  font-weight: 400;
}

#pricing .table-responsive {
  padding: 20px;
}

#ccpa_disclosures tr td:first-of-type {
  max-width: 400px;
}

